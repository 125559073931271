import React from "react";
import CollectionGrid from "../components/CollectionsGrid";

const GalleriesGrid = () => {
    const galleryItems = [
        {
            title: "Simulations on Exchange Art",
            description: "Exhibit on Exchange Art",
            links: [
                {
                    href: "https://exchange.art/simulations/exhibition",
                    text: "Exchange Art Exhibition",
                },
            ],
        },
        {
            title: "The Matter Melt Gallery",
            description:
                "A gallery featuring 1/1 glitch art photography from the collection, 'The Simulacrum Matter Melt' by Simulations. Interpretations, representations, simulations. The Universe needs meaning to exist.",
            links: [
                {
                    href: "https://aether.so/exhibit/the-matter-melt-gallery",
                    text: "The Simulation Gallery - Exhibit",
                },
                {
                    href: "https://aether.so/space/the-matter-melt-gallery",
                    text: "The Matter Melt Gallery - Virtual Space",
                },
                {
                    href: "https://www.mallow.art/collection/7i3aYZpJs3T58r3jAW5FJcHuss2VYbMB6D2c6QqSUCoL",
                    text: "The Matter Melt Gallery - Collection",
                },
            ],
        },
        {
            title: "The Simulacrum Gallery",
            description:
                "Have a look at some stellar artworks that I've collected on the Solana blockchain from many talented artists from across the globe and spectrum!",
            links: [
                {
                    href: "https://aether.so/exhibit/the-simulation-gallery",
                    text: "The Simulation Gallery - Exhibit",
                },
                {
                    href: "https://aether.so/space/the-simulation-gallery",
                    text: "The Simulation Gallery - Virtual Space",
                },
            ],
        },
        {
            title: "Flower Melt House",
            description:
                "This gallery features floral experiences from 'Simulation Flower Melt'",
            links: [
                {
                    href: "https://aether.so/exhibit/SimulationFlowerMelt",
                    text: "Flower Melt House",
                },
                {
                    href: "https://exchange.art/series/Simulation%20Flower%20Melt/nfts",
                    text: "Simulation Flower Melt Gallery - Collection",
                },
            ],
        },
        {
            title: "The USS Artemis - Audio Play 3D Promotional Gallery",
            description:
                'A gallery installation that features promotional artwork for the audio play, "USS Artemis".',
            links: [
                {
                    href: "https://aether.so/solaris/space/2040",
                    text: "USS Artemis Gallery",
                },
                {
                    href: "https://www.boathausstudios.com/uss-artemis/#uss-artemis-3dgallery",
                    text: "USS Artemis Website",
                },
            ],
        },
        {
            title: "The Meta Microwaves",
            description:
                "Pixelated microwave NFT collection created with Candymachine v2. This was the second NFT collection that I ever created. My ultimate goal was to see if I could upload assets to the blockchain and create a frontend for the NFT candymachine.",
            links: [
                {
                    href: "https://magiceden.io/marketplace/microwave",
                    text: "The Meta Microwaves on Magic Eden",
                },
                {
                    href: "https://www.tensor.trade/trade/microwave",
                    text: "The Meta Microwaves on Tensor",
                },
                {
                    href: "https://the-meta-microwaves.vercel.app/",
                    text: "Original Mint Site",
                },
            ],
        },
        {
            title: "Astro Data Colors",
            description:
                "🌌☄️ Asteroid Forecasts by Day. Each circle is an asteroid. The largest asteroids are in the back and the smallest are in the front. The closer to the big blue horizon, the closer the pass-by distance. This was the first NFT collection that I ever created using SolSea.",
            links: [
                {
                    href: "https://solsea.io/c/61ed875aa1f8bc91e75ec809",
                    text: "Astro Data Colors on SolSea",
                },
            ],
        },
    ];

    return (
        <div>
            <h1 className="poppins-title-italic">Galleries</h1>
            <div id="gallery-container">
                {galleryItems.map((item, index) => (
                    <div className="gallery-item" key={index}>
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                        {item.links.map((link, linkIndex) => (
                            <a
                                key={linkIndex}
                                href={link.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <p>{link.text}</p>
                            </a>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GalleriesGrid;
