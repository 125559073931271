// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/SimulacrumImagesStyle.css';

const SimulacrumImagesNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" className="navbar-logo">
                    <img src="/images/372NoBackground.png" alt="Simulacrum" className="navbar-logo-image" />
                </Link>
                <div className="menu-icon" onClick={toggleMenu}>
                    <i className={isOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>
                <ul className={isOpen ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item">
                        <Link to="/" className="nav-links" onClick={() => setIsOpen(false)}>
                            Home
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/about" className="nav-links" onClick={() => setIsOpen(false)}>
                            About
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link to="/galleries" className="nav-links" onClick={() => setIsOpen(false)}>
                            Galleries & Collections
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a href='https://muulti.xyz/' className="nav-links" target="_blank" rel="noopener noreferrer">
                            Muulti
                        </a>
                    </li>
                    <li className="nav-item">
                        <Link to="/gm-blockchain" className="nav-links" onClick={() => setIsOpen(false)}>
                            GM Block Chain Book
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/glossary" className="nav-links" onClick={() => setIsOpen(false)}>
                            Glossary
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/articles" className="nav-links" onClick={() => setIsOpen(false)}>
                            Articles
                        </Link>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <Link to="/services" className="nav-links" onClick={() => setIsOpen(false)}>*/}
                    {/*        Services*/}
                    {/*    </Link>*/}
                    {/*</li>*/}


                    {/*<li className="nav-item">*/}
                    {/*    <a href='https://explorer.simulacrumimages.com/' className="nav-links" target="_blank" rel="noopener noreferrer">*/}
                    {/*        Explorer*/}
                    {/*    </a>*/}
                    {/*</li>*/}

                    <li className="nav-item">
                        <Link to="/mimesis-castle" className="nav-links" onClick={() => setIsOpen(false)}>
                            Mimesis Castle
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default SimulacrumImagesNavbar;
