import React from 'react';
import {Link} from "react-router-dom";
const About = () => {
    return <div id="outer-glossary-container" className="outer-body-container">

        <div>
            <h1>The Simulacrum</h1>
            <div>
                <h3 className="poppins-sub-title-italic">Create</h3>
                <h3 className="poppins-sub-title-italic">Develop</h3>
                <h3 className="poppins-sub-title-italic">Educate</h3>
                <p>The Simulacrum is a Digital Art Production Lab dedicated to exploring and showcasing NFTs as an art medium and developing potential for creation, connection, education and beyond. Produced by Kyle Hinton aka Simulations.</p>
                <p>Find out more about Kyle Hinton at <a href="https://www.kylehintonprojects.com">Kyle Hinton Projects</a></p>
            </div>

                <div className="home-link-container">
                    <Link to="/gm-blockchain" className="home-links">
                        <h3 className="poppins-sub-title-italic">GM Block Chain Book</h3>
                    </Link>
                </div>
                <div className="home-link-container">
                    <Link to="/glossary" className="home-links">
                        <h3 className="poppins-sub-title-italic">Glossary</h3>
                    </Link>
                </div>
                <div className="home-link-container">
                    <Link to="/articles" className="home-links">
                        <h3 className="poppins-sub-title-italic">Articles</h3>
                    </Link>
                </div>
                <div className="home-link-container">
                    <Link to="/mimesis-castle" className="home-links">
                        <h3 className="poppins-sub-title-italic">Mimesis Castle</h3>
                    </Link>
                </div>
                <div className="home-link-container">
                    <Link to="/galleries" className="home-links">
                        <h3 className="poppins-sub-title-italic">Galleries</h3>
                    </Link>
                </div>
                <div className="home-link-container">
                    <Link to="/services" className="home-links">
                        <h3 className="poppins-sub-title-italic">Services</h3>
                    </Link>
                </div>

                <div className="home-link-container">
                    <a href='https://muulti.xyz/' className="home-links" target="_blank" rel="noopener noreferrer">
                        <h3 className="poppins-sub-title-italic">Muulti</h3>
                    </a>
                    <p>Token Gated Video Platform - Create token gated video playlists.</p>

                </div>


        </div>
        <div id="about-container" className="body-container">

        </div>
    </div>
};

export default About;