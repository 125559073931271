import React from "react";
import { Link } from "react-router-dom";


const CollectionGrid = () => {
    const collectionItems = [
        {
            title: "The Meta Microwaves",
            href: "https://magiceden.io/marketplace/microwave",
            id: "meta-microwave-collection-item"
        },
        {
            title: "Simulacrum Matter Melt",
            href: "https://www.mallow.art/collection/7i3aYZpJs3T58r3jAW5FJcHuss2VYbMB6D2c6QqSUCoL",
            id: "simulacrum-melt-collection-item"
        },
        {
            title: "Long Distance Communication",
            href: "https://www.mallow.art/collection/6GtPoMAKwgx5SLYfbL9uEr8avYoE4Bm24YFTiPGU6894",
            id: "long-distance-communication-collection-item"
        },

        {
            title: "A Staring Contest With the Abyss",
            href: "https://exchange.art/series/A%20Staring%20Contest%20With%20The%20Abyss",
            id: "staring-abyss-collection-item"
        },
        {
            title: "Simulation Sky Melt",
            href: "https://exchange.art/series/Simulation%20Sky%20Melt",
            id: "simulation-sky-melt-collection-item"
        },
        {
            title: "Something out of Nothing",
            href: "https://exchange.art/series/Something%20Out%20of%20Nothing",
            id: "something-nothing-collection-item"

        },
        {
            title: "Simulation Flower Melt",
            href: "https://exchange.art/series/Simulation%20Flower%20Melt",
            id: "simulation-flower-melt-collection-item"
        },
        {
            title: "An Oasis for Existence to Ponder Existence",
            href: "https://exchange.art/series/Simulation%20Flower%20Melt",
            id: "oasis-existence-ponder-collection-item"
        },
        {
            title: "Boot Lickers BBQ",
            href: "https://exchange.art/series/Boot%20Lickers%20BBQ",
            id: "boot-licking-collection-item"
        },
        {
            title: "Absurdable Universe",
            href: "https://exchange.art/series/Absurdable%20Universe",
            id: "absurdable-universe-collection-item"
        },
        {
            title: "Coffee Sticks",
            href: "https://exchange.art/series/Coffee%20Sticks",
            id: "coffee-sticks-collection-item"
        },
        {
            title: "Simulation Skull",
            href: "https://exchange.art/series/Simulation%20Skull",
            id: "simulation-skull-collection-item"
        },
        {
            title: "Br1ck5 4nd Bl0ck5",
            href: "https://exchange.art/series/Br1ck5%204nd%20Bl0ck5",
            id: "bricks-and-blocks-collection-item"
        },
        {
            title: "Glitch Bonkz",
            href: "https://exchange.art/series/Glitch%20Bonkz",
            id: "glitch-bonkz-collection-item"
        },
    ];


    return (
        <div>
            <h1 className="poppins-title-italic">Digital Art Collections</h1>
            <div id="collection-grid-container" className="collection-grid-container">
                {collectionItems.map((item, index)=>(
                    <a
                        key={index}
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                        className="collection-item-link"
                    >
                        <div className="collection-item" id={item.id || ""}>
                            <h1 className="collection-item-title">{item.title}</h1>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default CollectionGrid;