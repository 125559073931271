import React from 'react';
import {Link} from "react-router-dom";
import FrontPageGrid from "../components/FrontPageGrid";
import CollectionGrid from "../components/CollectionsGrid";

const Home = () => {
    return <div className="full-body-container">
                <div className="title-container">
                    <h1 className="poppins-title-italic">The Simulacrum</h1>
                    <p class="poppins-regular">The Simulacrum is a Digital Art Production Lab dedicated to exploring and showcasing NFTs as an art medium and developing potential for creation, connection, education and beyond.</p>
                    <div className="front-page-gallery-grid-container">
                    </div>
                </div>
                <div id="home-container" className="body-container">
                    <FrontPageGrid />
                    <CollectionGrid/>
                </div>
    </div>
};

export default Home;