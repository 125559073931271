import React from 'react';
import CollectionGrid from "../components/CollectionsGrid";
import GalleriesGrid from "../components/GalleriesGrid";

const Galleries = () => {
    return <div id="outer-gallery-container">
                <h1 className="poppins-title-italic">Galleries and Collections</h1>
                    <CollectionGrid/>
                    <GalleriesGrid/>
            {/*<h1 className="poppins-title-italic">Galleries</h1>*/}
            {/*    <div id="gallery-container" className="body-container">*/}
            {/*        <div className="gallery-item">*/}
            {/*            <h3>Simulations on Exchange Art</h3>*/}
            {/*            <p>Exhibit on Exchange Art</p>*/}
            {/*            <a href="https://exchange.art/simulations/exhibition"  target="_blank" rel="noopener noreferrer"><p>Exchange Art Exhibition</p></a>*/}
            {/*        </div>*/}
            {/*        <div className="gallery-item">*/}
            {/*            <h3>The Matter Melt Gallery</h3>*/}
            {/*            <p>A gallery featuring 1/1 glitch art photography from the collection, 'The Simulacrum Matter Melt' by Simulations.*/}
            {/*                Interpretations, representations, simulations*/}
            {/*                The Universe needs meaning to exist.</p>*/}
            {/*            <a href="https://aether.so/exhibit/the-matter-melt-gallery"  target="_blank" rel="noopener noreferrer"><p>The Simulation Gallery - Exhibit</p></a>*/}
            {/*            <a href="https://aether.so/space/the-matter-melt-gallery"  target="_blank" rel="noopener noreferrer"><p>The Matter Melt Gallery - Virtual Space</p></a>*/}
            {/*            <a href="https://www.mallow.art/collection/7i3aYZpJs3T58r3jAW5FJcHuss2VYbMB6D2c6QqSUCoL"  target="_blank" rel="noopener noreferrer"><p>The Matter Melt Gallery - Collection</p></a>*/}
            {/*        </div>*/}
            {/*        <div className="gallery-item">*/}
            {/*            <h3>The Simulacrum Gallery</h3>*/}
            {/*            <p>Have a look at some stellar artworks that I've collected on the Solana blockchain from many talented artists from across the globe and spectrum!</p>*/}
            {/*            <a href="https://aether.so/exhibit/the-simulation-gallery"  target="_blank" rel="noopener noreferrer"><p>The Simulation Gallery - Exhibit</p></a>*/}
            {/*            <a href="https://aether.so/space/the-simulation-gallery"  target="_blank" rel="noopener noreferrer"><p>The Simulation Gallery - Virtual Space</p></a>*/}
            {/*        </div>*/}


            {/*        <div className="gallery-item">*/}
            {/*            <h3>Flower Melt House</h3>*/}
            {/*            <p>This gallery features a floral experiences from 'Simulation Flower Melt'</p>*/}
            {/*            <a href="https://aether.so/exhibit/SimulationFlowerMelt"  target="_blank" rel="noopener noreferrer"><p>Flower Melt House</p></a>*/}
            {/*            <a href="https://exchange.art/series/Simulation%20Flower%20Melt/nfts"  target="_blank" rel="noopener noreferrer"><p>Simulation Flower Melt Gallery - Collection</p></a>*/}

            {/*        </div>*/}
            {/*        <div className="gallery-item">*/}
            {/*            <h3>The USS Artemis - Audio Play 3d Promotional Gallery</h3>*/}
            {/*            <p>A gallery installation that features promotional artwork for the audio play, "USS Artemis".</p>*/}
            {/*            <a href="https://aether.so/solaris/space/2040" target="_blank" rel="noopener noreferrer"><p>USS Artemis Gallery</p></a>*/}
            {/*            <a href="https://www.boathausstudios.com/uss-artemis/#uss-artemis-3dgallery" target="_blank" rel="noopener noreferrer"><p>USS Artemis Website</p></a>*/}

            {/*        </div>*/}

            {/*        <div className="gallery-item">*/}
            {/*            <h3>The Meta Microwaves</h3>*/}
            {/*            <p>Pixelated microwave NFT collection created with Candymachine v2.</p>*/}
            {/*            <p>This was the second NFT collection that I ever created. My ultimate goal was to see if I could upload assets to the blockchain and create a frontend for the NFT candymachine.</p>*/}
            {/*            <a href="https://magiceden.io/marketplace/microwave"  target="_blank" rel="noopener noreferrer"><p>The Meta Microwaves on Magic Eden</p></a>*/}
            {/*            <a href="https://www.tensor.trade/trade/microwave"  target="_blank" rel="noopener noreferrer"><p>The Meta Microwaves on Tensor</p></a>*/}
            {/*            <a href="https://the-meta-microwaves.vercel.app/"  target="_blank" rel="noopener noreferrer"><p>Original Mint Site</p></a>*/}
            {/*        </div>*/}
            {/*        <div className="gallery-item">*/}
            {/*            <h3>Astro Data Colors</h3>*/}
            {/*            <p>*/}
            {/*                🌌☄️ Asteroid Forecasts by Day*/}
            {/*                Each circle is an asteroid.*/}
            {/*                The largest asteroids are in the back and the smallest are in the front.*/}
            {/*                The closer to the big blue horizon, the closer the pass-by distance.*/}
            {/*            </p>*/}
            {/*            <p>*/}
            {/*                This was the first NFT collection that I ever created using SolSea.*/}
            {/*            </p>*/}
            {/*            <a href="https://solsea.io/c/61ed875aa1f8bc91e75ec809"  target="_blank" rel="noopener noreferrer"><p>Astro Data Colors on SolSea</p></a>*/}

            {/*        </div>*/}

            {/*    </div>*/}


            </div>

};

export default Galleries;