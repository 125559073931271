import React from 'react';
import {Link} from "react-router-dom";

const Home = () => {
    return <div className="full-body-container">
                <div className="title-container">
                    <h1 className="poppins-title-italic">The Simulacrum</h1>
                    <p class="poppins-regular">The Simulacrum is a Digital Art Production Lab dedicated to exploring and showcasing NFTs as an art medium and developing potential for creation, connection, education and beyond. Produced by Kyle Hinton aka Simulations. Find more info about Simulations at <a href="https://www.kylehintonprojects.com" target="_blank" rel="noopener noreferrer">Kyle Hinton Projects</a></p>
                    <h3>Featured Project</h3>
                    <h3 className="poppins-sub-title-italic">DAM Residency - Fractured Foundation</h3>
                    <p>For the Fall DAM Residency, 2024, I got to create a few artworks using the prompt 'Brainwaves'. A number of creations emerged including these new artworks for this series called, 'A Staring Contest With The Abyss'.

                        The artwork that emerged from the DAM residency that I want to spotlight, is called 'Fractured Foundation'. It is a dedication to mental health and the feeling of having a fractured foundation. It is a piece of glitch photography that used images of cracked foundations as the source material.

                        Grateful for the opportunity to learn and create with such a stellar bouquet of artists, collectors and mentors!</p>
                    <p>Make a bid on <a href="https://exchange.art/single/8AJc3Sih4suEh4iafcD89LsM9BuX9Js2EU5dBHRD97GQ" target="_blank" rel="noopener noreferrer">Exchange Art</a></p>
                </div>
                <div id="outer-home-container" className="outer-body-container">
                    <div>
                        <img className="body-image" id="home-image" src="images/FracturedFoundation.jpg" alt="Gm Blockchain"/>
                    </div>
                    <div id="home-container" className="body-container">
                        <div className="home-links-container">
                            <div>
                                <p>Fractured Foundation - This is a dedication to mental health and the feeling of having a fractured foundation.</p>
                                <p>…</p>
                                <p>A fractured foundation, worn through time and experience. </p>
                                <p>Picking up dis-assembled thoughts, pieces of the self, as the ground disappears from underneath.</p>
                                <p>The cracks that appear from erosion and impact, that may cause you to trip and fall in.</p>
                                <p>A brain,</p>
                                <p>Set aflame,</p>
                                <p>In free fall</p>
                                <p>Through the cracks.</p>
                                <p>With self-awareness,</p>
                                <p>Openness,</p>
                                <p>Safety and</p>
                                <p>Reassurance,</p>
                                <p>Healing,</p>
                                <p>Trust and</p>
                                <p>Growth,</p>
                                <p>A new foundation is built.</p>
                            </div>
                        </div>
                </div>
                </div>
    </div>
};

export default Home;